import { Col, Form, Input, Modal, Radio, Row, message } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import cacheService from "../../../services/cache-service";
import clientService from "../../../services/http-client";
import { LoginImg, logo } from "../../imagepath";
const Login = () => {
  var settings = {
    //autoWidth: true,
    items: 2,
    margin: 25,
    dots: true,
    nav: true,
    navText: ['<i className="fas fa-arrow-left"></i>', '<i className="fas fa-arrow-right"></i>'],

    loop: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };
  const onFinish = async (value) => {
    try {
      const res = await clientService.api.authLogin(value);
      if (res) {
        message.success("Đăng nhập thành công !");
        cacheService.setValue(res);
        window.location.href = `/`;
      }
    } catch (e) {
      Modal.error({
        title: "Lỗi đăng nhập",
        content: e.msg ?? "Vui lòng kiểm tra lại tên đăng nhập hoặc mật khẩu",
      });
    }
  };
  return (
    <>
      <div className="main-wrapper log-wrap">
        <div className="row">
          {/* Login Banner */}
          <div className="col-md-6 login-bg">
            <OwlCarousel {...settings} className="owl-carousel login-slide owl-theme">
              <div className="welcome-login">
                <div className="login-banner">
                  <img src={LoginImg} className="img-fluid" alt="Logo" />
                </div>
                <div className="mentor-course text-center">
                  <h2>
                    Đào tạo trực tuyến <br />
                    Viện Y Dược Lâm Sàng
                  </h2>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                    enim ad minim veniam.
                  </p> */}
                </div>
              </div>
            </OwlCarousel>
          </div>
          {/* /Login Banner */}
          <div className="col-md-6 login-wrap-bg">
            {/* Login */}
            <div className="login-wrapper">
              <div className="loginbox">
                <div className="w-100">
                  <div className="img-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                    <div className="back-home">
                      <Link to="/">Quay lại trang chủ</Link>
                    </div>
                  </div>
                  <h1>Đăng nhập</h1>
                  <Form layout="vertical" onFinish={onFinish} size="large">
                    <Form.Item name={"username"} label="Email" rules={[{ required: true, message: "Vui lòng nhập email" }]}>
                      <Input placeholder="Nhập email" />
                    </Form.Item>
                    <Form.Item name={"password"} label="Mật khẩu" rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}>
                      <Input.Password placeholder="Nhập mật khẩu" />
                    </Form.Item>
                    <Row>
                      <Col span={12} xs={24}>
                        <Form.Item name={"at"} label="Vai trò" rules={[{ required: true, message: "Vui lòng chọn vai trò" }]}>
                          <Radio.Group style={{ width: 300 }}>
                            <Row style={{ flexFlow: "unset" }}>
                              <Radio value={"GiangVien"}>Giảng viên</Radio>
                              <Radio value={"HocVien"}>Học viên</Radio>
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="d-grid">
                      <button className="btn btn-start" type="submit">
                        Đăng nhập
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            {/* /Login */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
