import { useQuery } from "@tanstack/react-query";
import { List } from "antd";
import AOS from "aos";
import "aos/dist/aos.css";
import * as currencyFormatter from "currency-formatter";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import Loginbg from "../../assets/img/banner.png";
import clientService from "../../services/http-client";
import Footer from "../footer";
import Header from "../header";
import {
  CertificateIcon,
  CourseIcon,
  GratuateIcon,
  Icon01,
  Icon02,
  Icon03,
  Icon04,
  Icon1,
  Icon2,
  Join,
  PencilIcon,
  User1,
  bannerimg,
} from "../imagepath";
import Companies from "./slider/companies";

export const Home = () => {
  const [isActive, setIsActive] = useState(false);
  const [isActivetwo, setIsActivetwo] = useState(false);
  const [isActivethree, setIsActivethree] = useState(false);
  const [isActivefour, setIsActivefour] = useState(false);
  const [isActivefive, setIsActivefive] = useState(false);
  const [isActivesix, setIsActivesix] = useState(false);

  const { data: khoaHocList, isLoading } = useQuery({
    queryKey: ["khoaHocGetPublic", { noiBat: true }],
    queryFn: () =>
      clientService.api.khoaHocGetPublic({
        query: {
          where: {
            noiBat: true,
          },
        },
      }),
  });

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const [searchText, setSearchText] = useState("");
  return (
    <>
      <div className="main-wrapper">
        <Header />
        {/* banner */}
        <section
          className="home-slide d-flex align-items-center"
          style={{ backgroundImage: "url(" + Loginbg + ")" }}
        >
          <div className="container">
            <div className="row ">
              <div className="col-md-7">
                <div className="home-slide-face aos" data-aos="fade-up">
                  <div className="home-slide-text ">
                    <span style={{fontSize:43}}>Đào tạo liên tục</span>
                    <h1 style={{color:"#0060b1"}}>Viện Y Dược Lâm Sàng</h1>
                    <p>
                      Là tập hợp các chuyên gia, các nhà khoa học có kinh
                      nghiệm, chuyên môn cao để cung cấp dịch vụ khoa học công
                      nghệ trong lĩnh vực khoa học sức khỏe
                    </p>
                  </div>
                  <div className="banner-content">
                    <div className="form-inner">
                      <div className="input-group homeSearch">
                        <i className="fa-solid fa-magnifying-glass search-icon" />
                        <input
                          className="form-control"
                          placeholder="Tìm kiếm khóa học"
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                        <a
                          className="btn sub-btn"
                          href={`/course?search=${searchText}`}
                        >
                          <i className="fas fa-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <div className="girl-slide-img aos" data-aos="fade-up">
                  <img src={bannerimg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /banner */}

        {/* Home banner bottom */}
        <section className="section student-course">
          <div className="container">
            <div className="course-widget">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="course-full-width">
                    <div className="blur-border course-radius align-items-center aos">
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={PencilIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            {/* <span>10</span>K */}
                            <span className="d-flex">
                              <CountUp
                                start={0}
                                end={10}
                                delay={1}
                                duration={4}
                              />
                              K
                            </span>
                          </h4>
                          <p>Lớp học đã mở</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div className="blur-border course-radius aos">
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={CourseIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <CountUp start={0} end={200} delay={1} />+
                            </span>
                          </h4>
                          <p>Giảng viên</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div className="blur-border course-radius aos">
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={CertificateIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <CountUp
                                start={0}
                                end={6}
                                delay={1}
                                duration={5}
                              />
                              K+
                            </span>
                          </h4>
                          <p>Chứng nhận đã cấp</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div className="blur-border course-radius aos">
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src={GratuateIcon} alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span className="d-flex">
                              <CountUp
                                start={0}
                                end={60}
                                delay={1}
                                duration={2}
                              />
                              K +
                            </span>
                          </h4>
                          <p>Học viên theo học</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Home banner bottom */}

        {/* Top Category with Owl Carousel */}
        {/* <TopCategory /> */}
        {/* Top Category with Owl Carousel */}

        {/* What's new Featured Course */}
        <section className="section new-course">
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head">
                <span>Khóa học mới nhất</span>
              </div>
              <div className="all-btn all-category d-flex align-items-center">
                <Link to="/course" className="btn btn-primary">
                  Tất cả khóa học
                </Link>
              </div>
            </div>
            <div className="section-text aos" data-aos="fade-up">
              {/* <p className="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum gravida maecenas augue elementum et neque.
                Suspendisse imperdiet.
              </p> */}
            </div>
            <div className="course-feature">
              <div className="row">
                <List
                  dataSource={khoaHocList}
                  pagination={{
                    pageSize: 6,
                    hideOnSinglePage: true,
                    align: "center",
                    total: 6,
                  }}
                  className="khoahoc-list"
                  renderItem={(item, index) => {
                    const tutor = item?.coCauNhanSu?.find(
                      (i) => i.chucVu?.id === 2
                    );
                    return (
                      <div key={index} className="col-lg-4 col-md-6 d-flex">
                        <div
                          className="course-box d-flex aos"
                          data-aos="fade-up"
                        >
                          <div className="product">
                            <div className="product-img">
                              <Link to={`/course-details/${item.id}`}>
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src={item?.hinhAnh?.path ?? Course10}
                                />
                              </Link>
                              <div className="price">
                                <h3>
                                  {currencyFormatter.format(item.giaTien ?? 0, {
                                    code: "VND",
                                    symbol: "đ",
                                  })}
                                </h3>
                              </div>
                            </div>
                            <div className="product-content">
                              <div className="course-group d-flex">
                                <div className="course-group-img d-flex">
                                  <img
                                    src={tutor?.user?.avatar?.path ?? User1}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div className="course-name">
                                    <p>Giảng viên</p>
                                    <p
                                      style={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {tutor?.user?.fullName}
                                    </p>
                                  </div>
                                </div>
                                <div className="course-share d-flex align-items-center justify-content-center">
                                  <Link to="#">
                                    <i className="fa-regular fa-heart" />
                                  </Link>
                                </div>
                              </div>
                              <h3 className="title">
                                <Link to={`/course-details/${item.id}`}>
                                  {item.tenKhoaHoc}
                                </Link>
                              </h3>
                              <div className="course-info d-flex align-items-center">
                                <div className="rating-img d-flex align-items-center">
                                  <img src={Icon1} alt="" />
                                  <p>Số tiết: {item.soTiet}</p>
                                </div>
                                <div className="course-view d-flex align-items-center">
                                  <img src={Icon2} alt="" />
                                  <p>
                                    Khai giảng:{" "}
                                    {item?.ngayKhaiGiang
                                      ? dayjs(item?.ngayKhaiGiang).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "Chưa có"}
                                  </p>
                                </div>
                              </div>
                              <div className="all-btn all-category d-flex align-items-center">
                                {dayjs().isAfter(item.ngayKetThuc) ? (
                                  <span className="btn btn-primary disabled" style={{ color: "red", borderColor: "red" }}>
                                    Khóa học đã kết thúc
                                  </span>
                                ) : (
                                  <Link to={`/course-enroll/${item.id}`} className="btn btn-primary">
                                    Ghi danh
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        {/* What's new Featured Course */}

        {/* Master Skills */}
        <section className="section master-skill">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="section-header aos" data-aos="fade-up">
                  <div className="section-sub-head">
                    <h2>
                      Nâng cao năng lực nghề nghiệp để phát triển sự nghiệp của
                      bạn.
                    </h2>
                  </div>
                </div>
                <div className="section-text aos" data-aos="fade-up">
                  <p>
                    Để trở thành nhân viên y tế thành công, bạn nên làm việc một
                    cách khác biệt, bằng cách cập nhật kiến thức liên tục, thực
                    hành kỹ năng thành thạo và biểu lộ thái độ phù hợp với khách
                    hàng và người thân của họ!
                  </p>
                </div>
                <div className="career-group aos" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon02} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Làm việc một cách khác biệt</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon03} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Cập nhật Kiến thức liên tục</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon04} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Kỹ năng Y khoa</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img src={Icon01} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <p>Thái độ tích cực</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 d-flex align-items-end">
                <div className="career-img aos" data-aos="fade-up">
                  <img src={Join} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Master Skills */}

        {/* Companies */}
        <section className="section lead-companies">
          <div className="container">
            <div className="section-header aos" data-aos="fade-up">
              <div className="section-sub-head feature-head text-center">
                <span>Được tin tưởng bởi</span>
                <h2>
                  Hơn 50 trường đại học, bệnh viện và công ty hàng đầu hợp tác
                </h2>
              </div>
            </div>
            <div className="lead-group aos" data-aos="fade-up">
              <Companies />
            </div>
          </div>
        </section>
        {/* Companies */}
        {/* Footer */}
        <Footer />
        {/* /Footer */}
      </div>
    </>
  );
};

export default Home;
